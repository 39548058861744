import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vw-personal-info',
  templateUrl: './vw-personal-info.component.html',
  styleUrls: ['./vw-personal-info.component.css']
})
export class VwPersonalInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
