import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vw-exam',
  templateUrl: './vw-exam.component.html',
  styleUrls: ['./vw-exam.component.css']
})
export class VwExamComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
